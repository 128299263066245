
import abi from "./contract/GlobalPool.json";
import abi1 from "./contract1/GlobalPool.json";

import Buy from "./component/buy";
import Buy1 from "./component/buy1";
import Buy2 from "./component/buy2";
import Buy3 from "./component/buy3";
import Buy4 from "./component/buy4";
import Buy5 from "./component/buy5";
import Buy6 from "./component/buy6";
import Buy7 from "./component/buy7";
import Buy8 from "./component/buy8";
import Buy9 from "./component/buy9";

import "./css/style.css";
import "./css/bootstrap.min.css";

import "./logo.png";

import "./161.png";
import "./162.png";
import "./163.png";
import "./164.png";
import "./165.png";
import "./166.png";

import "./168.png";
import "./169.png";
import "./1610.png";
import "./1611.png";
import "./1612.png";
import "./box.png";
import "./copy.png";


import { useState, useEffect } from "react";
import { ethers } from "ethers";

function App() {

  const [state, setState] = useState({
    provider: null,
    signer: null,
    contract: null,
  });
  
  const [state1, setState1] = useState({
    provider: null,
    signer: null,
    contract1: null,
  });
  const [account, setAccount] = useState("None");
  const[b,setB]=useState("None");
  const[direct,setDirect]=useState("None");
  const[directs,setDirects]=useState("None");
  const[directs1,setDirects1]=useState("None");
  const[level,setLevel]=useState("None");
  const[levels,setLevels]=useState("None");
  const[auto,setAuto]=useState("None");
  const[auto1,setAuto1]=useState("None");
  const[auto2,setAuto2]=useState("None");
  const[auto3,setAuto3]=useState("None");
  const[auto4,setAuto4]=useState("None");
  const[auto5,setAuto5]=useState("None");
  const[auto6,setAuto6]=useState("None");
  const[auto7,setAuto7]=useState("None");
  const[bonus,setBonus]=useState("None");
  const[bonus1,setBonus1]=useState("None");
  const[bonus2,setBonus2]=useState("None");
  const[bonus3,setBonus3]=useState("None");
  const[nbonus,setnBonus]=useState("None");
  const[nbonus1,setnBonus1]=useState("None");
  const[nbonus2,setnBonus2]=useState("None");
  const[nbonus3,setnBonus3]=useState("None");

  const [ntasks,setnTasks] = useState([]);
  const[pack,setpackage]=useState("None");
  const[refer,setrefer]=useState("None");
const [tasks,setTasks] = useState([]);
const [total,setTotal]=useState("None");
const [req,setreq]=useState("None");
const [tt,settt]=useState("None");
const[dd,setDD]=useState("None");
const[referlink,setreflink]=useState("None");
const[bonanza,setBonanza]=useState("None");
const[bonanza1,setBonanza1]=useState("None");
const[bonanza2,setBonanza2]=useState("None");
const[bonanza3,setBonanza3]=useState("None");

const[booster,setBooster]=useState("None");
const[au,setAu]=useState("None");
const[au1,setAu1]=useState("None");
const[au2,setAu2]=useState("None");
const[au3,setAu3]=useState("None");
const[au4,setAu4]=useState("None");

const[nbonanza,setnBonanza]=useState("None");
const[nbonanza1,setnBonanza1]=useState("None");
const[nbonanza2,setnBonanza2]=useState("None");
const[nbonanza3,setnBonanza3]=useState("None");
const[wid,setwid]=useState("None");


  useEffect(() => {
    const connectWallet = async () => {
      const contractAddress = "0x514ff4c1d294c9c2a886c46a7f1f13d47c0af5ff";
      const contractABI = abi.abi;

      const contractAddress1 = "0xaa41d07148108ba5c36a6c6316eb034a3ccdde3e";
      const contract2abi = abi1.abi1;

      const searchParams = new URLSearchParams(window.location.search);
      const myParam = searchParams.get('ref');
      console.log(myParam);
      
      
      document.querySelector("#name").value=myParam;
  if(myParam == null)
  {
    document.querySelector("#name").value="0x0667E94ad4cc25543A4Ff2906262B31672Cb18a7";
  }
      try {
        const { ethereum } = window;
        document.querySelector("#test").value = "Upgrade USDT 30";
        if (ethereum) {
          const account = await ethereum.request({
            method: "eth_requestAccounts",
           
          });
         
          window.ethereum.on("chainChanged", () => {
            window.location.reload();
          });

          window.ethereum.on("accountsChanged", () => {
            
            window.location.reload();
          });
          const provider = new ethers.providers.Web3Provider(ethereum);
          try {
            const selectedNetwork = await provider.getNetwork();
          
            if (selectedNetwork.chainId !== 56) {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x38' }], // Binance Smart Chain Mainnet chainId is 97 in decimal, which is 0x61 in hex
              });
              console.log("You have switched to the Binance network");
            } else {
              console.log("You are already on the Binance network");
            }
          } catch (switchError) {
            // The network has not been added to MetaMask
            if (switchError.code === 4902) {
              console.log("Please add the Binance network to MetaMask");
            } else {
              console.log("Cannot switch to the network");
            }
          }
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
            contractAddress,
            contractABI,
            signer 
          );
          const contract1 = new ethers.Contract(
            contractAddress1,
            contract2abi,
            signer
          );


          setAccount(account);
          setState({ provider, signer, contract });
          setState1({ provider, signer, contract1 });

          
          document.querySelector("#us").value  = account[0];
          let alltasks = await contract.getMemos(account[0]);
          setTasks(alltasks);
          
          const b =Number(alltasks[2]);
          const refer = (alltasks[1]);
         setrefer(refer);
         document.querySelector("#hid").value  = (alltasks[1]);
         const direct =Number(alltasks[2]);
         setDirect(direct);
         const directs =  Number(alltasks[29]);
         setDirects(directs);
         const directs1 =  Number(alltasks[31]);
         setDirects1(directs1);

         const au1 = Number(alltasks[19]) ;
         setAu1(au1);
         const au2 = Number(alltasks[20]) ;
         setAu2(au2);
         const au3 = Number(alltasks[21]) ;
         setAu3(au3);
         const au4 = Number(alltasks[22]) ;
         setAu4(au4);
         
        
         const levels = Number(alltasks[30]) * 0.6;
         setLevels(levels);
         
         const level = Number(alltasks[3]) + levels ;
         setLevel(level);
         const auto = Number(alltasks[4]);
         setAuto(auto);
         const auto1 = Number(alltasks[11]);
         setAuto1(auto1);
         const auto2 = Number(alltasks[12]);
         setAuto2(auto2);
         
         const auto3 = Number(alltasks[13]);
         setAuto3(auto3);
         
         const auto4 = Number(alltasks[14]);
         setAuto4(auto4);
         const auto5 = Number(alltasks[15]);
         setAuto5(auto5);
         const auto6 = Number(alltasks[16]);
         setAuto6(auto6);
         const auto7 = Number(alltasks[17]);
         setAuto7(auto7);
         

         
         
         
         
         
         const bonus = Number(alltasks[5]) * 20;
         setBonus(bonus);
         const bonus1 = Number(alltasks[24]) * 40;
         setBonus1(bonus1);
         const bonus2 = Number(alltasks[26]) * 80;
         setBonus2(bonus2);
         const bonus3 = Number(alltasks[28]) * 160;
         setBonus3(bonus3);
         
         const total = Number(alltasks[10]);
         setTotal(total);

        
         const au = auto  + auto1 + auto2 + auto3 + auto4 + auto5 + auto6 + auto7;
         setAu(au);
       
         
         const pack = Number(alltasks[6]);
         document.querySelector("#pp").value  = Number(alltasks[6]);
         
    
    setpackage(pack);
    const bonanza = Number(alltasks[7]);
         setBonanza(bonanza);
         const bonanza1 = Number(alltasks[23]);
         setBonanza1(bonanza1);
         const bonanza2 = Number(alltasks[25]);
         setBonanza2(bonanza2);
         const bonanza3 = Number(alltasks[27]);
         setBonanza3(bonanza3);
         const booster = Number(alltasks[18]);
         setBooster(booster);
    const dd = new Date(alltasks[8].timestamp * 1000).toLocaleString();
    setDD(dd);
         setB(b);
         const referlink = 'https://senjepowers.live/?ref='+ account;
         setreflink(referlink);

         document.querySelector("#link").value =referlink;
         const wid =(((Number(alltasks[0])) + 1) + 9200 ) * 49 ;
          setwid(wid);
          
         let ntasks = await contract1.getMemos(account[0]);
         setTasks(setnTasks);
         
         const nbonus = Number(ntasks[8]) * 12;
         setnBonus(nbonus);
         const nbonus1 = Number(ntasks[9]) * 240;
         setnBonus1(nbonus1);
         const nbonus2 = Number(ntasks[10]) * 400;
         setnBonus2(nbonus2);
         const nbonus3 = Number(ntasks[11]) * 800;
         setnBonus3(nbonus3);

         const nbonanza = Number(ntasks[4]);
         setnBonanza(nbonanza);
         const nbonanza1 = Number(ntasks[5]);
         setnBonanza1(nbonanza1);
         const nbonanza2 = Number(ntasks[6]);
         setnBonanza2(nbonanza2);
         const nbonanza3 = Number(ntasks[7]);
         setnBonanza3(nbonanza3);
    

         const tt = directs  + bonus + auto + total + auto1 + auto2 + auto3 + auto4 + auto5 + auto6 + auto7 + level;
         settt(tt);


        
    if(pack==30)
    { 
      document.querySelector("#test").value = "Upgrade USDT 50";
    
    }
    else if(pack==80)
    { 
      document.querySelector("#test").value = "Upgrade USDT 100";
    
    }
    else if(pack ==180)
    {
      document.querySelector("#test").value = "Upgrade USDT 250";
    }
    else if(pack ==430)
    {
      
      document.querySelector("#test").value = "Upgrade USDT 500";
    }
    else if(pack ==930)
    {
      document.querySelector("#test").value = "Upgrade USDT 1000";
    }
    
    else if(pack ==1930)
    {
      document.querySelector("#test").value = "Upgrade USDT 2000";
    }
    else if(pack > 3929)
    {
      

      document.querySelector("#test").value = "All Packages Purchased";
    }
    

    console.log(pack);
    
     
        } else {
          alert("Please install metamask");
        }
      } catch (error) {
        console.log(error);
      }
    };
    
     
    
    
    connectWallet();
    
  }, []);

 
  // console.log(state);
        
       
  return (
   
<div style={{backgroundColor:"black"}}>
<input type="hidden" id ="link"></input>
    <input type="hidden" id ="hid"></input>
    <input type="hidden" id ="us"></input>
    <input type="hidden" id ="pp"></input>

    <div class="page-container" >
 
      
            
      
              
               <table width="90%">
               <tr>
  <td style={{width: "100px",color: "white",textAlign :"center",verticalAlign:"center", width :"80%", 
}} >
  <img src ="logo.png" width="120px" height="120px" />
  </td></tr>        
<tr>
  <td style={{width: "100px",color: "white",textAlign :"center",verticalAlign:"center", width :"80%", 
}} ><h2> An Ultimate Decentralized Platform<br></br>For Best Earning.</h2></td></tr>
         </table>      
           
             
         <div class="clearfix"> </div>	
         <table width="100%">
<tr>  
    <td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"left", width :"90%", textAlign: "left",
color: "white",
}}>
  <h6>WELCOME TO SENJE POWERS : USER ID NUMBER - {wid}</h6>
  <br></br>
  </td>
  <td style={{width:"5%" ,}}></td>

</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
    
  <td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"left", width :"90%", textAlign: "left",
color: "white",
}}>
  
  <h6>REFER LINK - {referlink} <button><img src ="copy.png" height={"25px"} onClick={() => {
  navigator.clipboard.writeText(referlink);}} /></button></h6>
  <br></br>
  </td>


</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
    
  <td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"left", width :"90%", textAlign: "left",
color: "white",
}}>
  
  <h6>MY SPONSOR - {refer}</h6>
  <br></br>
  </td>


</tr>
<tr><td></td><td></td><td></td></tr>
</table>


         <br></br>  
         <table width="100%">
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>TOTAL PACKAGE PURCHASED</h5>
<h6>  USDT {pack}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr><tr><td></td><td></td><td></td></tr>
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
 
  <h5>MY DIRECT TEAM</h5>
<h6>   {direct}  </h6><br></br> </td>

<td style={{width:"5%" ,}}></td>
</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>MY DIRECT INCOME</h5>
<h6>  USDT {directs}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr><tr><td></td><td></td><td></td></tr>
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>DIRECT LEVEL UPGRADE INCOME</h5>
<h6>  USDT {total}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr><tr><td></td><td></td><td></td></tr>
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>DIRECT BOOSTER INCOME</h5>
<h6>  USDT {directs1}  </h6><br></br> </td>

<td style={{width:"5%" ,}}></td>
</tr><tr><td></td><td></td><td></td></tr>
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>MY LEVEL UPGRADE INCOME</h5>
<h6>  USDT {level}

 </h6><br></br> </td>

 <td style={{width:"5%" ,}}></td>
</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
<td style={{width:"5%" ,}}></td>
<td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"80%",  textAlign: "center",
color: "white",
}}>
  
  <h5>MY SILVER POOL INCOME</h5>
<h6>  USDT {auto}  </h6> <br></br></td>
<td style={{width:"5%" ,}}></td>
</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
<td style={{width:"5%" ,}}></td>
<td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"80%",textAlign: "center",
color: "white",
}}>
  
  <h5>MY RUBY POOL INCOME</h5>
<h6>  USDT {auto1}  </h6> <br></br></td>

<td style={{width:"5%" ,}}></td>
</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  

<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>

  <h5>MY PEARL POOL INCOME</h5>
<h6>  USDT {auto2}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>
</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>MY  CROWN POOL INCOME</h5>
<h6>  USDT {auto3}  </h6><br></br> </td><td style={{width:"5%" ,}}></td>
</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  


<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>MY TOPAZ POOL INCOME</h5>
<h6>  USDT {auto4}  </h6> <br></br></td><td style={{width:"5%" ,}}></td>
</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>MY DIAMOND POOL INCOME</h5>
<h6>  USDT {auto5}  </h6><br></br> </td><td style={{width:"5%" ,}}></td>

</tr>
<tr><td></td><td></td><td></td></tr>
<tr>    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
 
  <h5>MY PLATINUM POOL INCOME</h5>
<h6>  USDT {auto6}  </h6><br></br></td><td style={{width:"5%" ,}}></td>
</tr>

<tr><td></td><td></td><td></td></tr>

<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>BOOSTER - 25 AVAILABLE</h5>

<h6>  USDT {au1}  </h6><br></br> </td><td style={{width:"5%" ,}}></td>


</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>BOOSTER - 50 AVAILABLE</h5>

<h6>  USDT {au2}  </h6><br></br> </td><td style={{width:"5%" ,}}></td>


</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>

  <h5>BOOSTER - 100 AVAILABLE</h5>

<h6>  USDT {au3}  </h6><br></br> </td><td style={{width:"5%" ,}}></td>


</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>

  <h5>BOOSTER - 200 AVAILABLE</h5>

<h6>  USDT {au4}  </h6><br></br> </td><td style={{width:"5%" ,}}></td>


</tr>

<tr><td></td><td></td><td></td></tr>

<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
 
  <h5>PURCHASE BOOSTER </h5>
                <h6>USDT - 15</h6>
								 <Buy6 state1={state1} />
<br></br> </td><td style={{width:"5%" ,}}></td>


</tr>
<tr><td></td><td></td><td></td></tr>

<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
 
  <h5>PURCHASE BOOSTER </h5>
                <h6>USDT - 25</h6>
								 <Buy1 state={state} />
<br></br> </td><td style={{width:"5%" ,}}></td>


</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
 
  <h5>PURCHASE BOOSTER </h5>
                <h6>USDT - 50</h6>
								 <Buy2 state={state} />
<br></br> </td><td style={{width:"5%" ,}}></td>


</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
 
  <h5>PURCHASE BOOSTER </h5>
                <h6>USDT - 100</h6>
								 <Buy4 state={state} />
<br></br> </td><td style={{width:"5%" ,}}></td>


</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  <h5>PURCHASE BOOSTER </h5>
                <h6>USDT - 200</h6>
								 <Buy5 state={state} />
<br></br> </td><td style={{width:"5%" ,}}></td>


</tr>
<tr><td></td><td></td><td></td></tr>

<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
 
  <h5>PURCHASE BOOSTER </h5>
                <h6>USDT - 300</h6>
								 <Buy7 state1={state1} />
<br></br> </td><td style={{width:"5%" ,}}></td>


</tr>

<tr><td></td><td></td><td></td></tr>

<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
 
  <h5>PURCHASE BOOSTER </h5>
                <h6>USDT - 500</h6>
								 <Buy8 state1={state1} />
<br></br> </td><td style={{width:"5%" ,}}></td>


</tr>
<tr><td></td><td></td><td></td></tr>

<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
 
  <h5>PURCHASE BOOSTER </h5>
                <h6>USDT - 1000</h6>
								 <Buy9 state1={state1} />
<br></br> </td><td style={{width:"5%" ,}}></td>


</tr>

<tr><td></td><td></td><td></td></tr> 



<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>BOOSTER - 15  PURCHASED</h5>
<h6>   {nbonanza}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr>

<tr><td></td><td></td><td></td></tr> 



<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>BOOSTER - 25  PURCHASED</h5>
<h6>   {bonanza}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr>
<tr><td></td><td></td><td></td></tr>


<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>BOOSTER - 50 PURCHASED</h5>
<h6>   {bonanza1}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr>

<tr><td></td><td></td><td></td></tr>

<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>BOOSTER - 100 PURCHASED</h5>
<h6>   {bonanza2}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr>
<tr><td></td><td></td><td></td></tr>


<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>BOOSTER - 200 PURCHASED</h5>
<h6>   {bonanza3}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr>


<tr><td></td><td></td><td></td></tr> 



<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>BOOSTER - 300  PURCHASED</h5>
<h6>   {nbonanza1}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr>
<tr><td></td><td></td><td></td></tr> 



<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>BOOSTER - 500  PURCHASED</h5>
<h6>   {nbonanza2}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr>

<tr><td></td><td></td><td></td></tr> 



<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>BOOSTER - 1000  PURCHASED</h5>
<h6>   {nbonanza3}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr>
<tr><td></td><td></td><td></td></tr>
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
 
  <h5>MY BOOSTER - 15 INCOME</h5>
<h6>  USDT {nbonus}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr>

<tr><td></td><td></td><td></td></tr> 
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
 
  <h5>MY BOOSTER - 25 INCOME</h5>
<h6>  USDT {bonus}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr>

<tr><td></td><td></td><td></td></tr> 
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>MY BOOSTER - 50 INCOME</h5>
<h6>  USDT {bonus1}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr>

<tr><td></td><td></td><td></td></tr> 
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>MY BOOSTER - 100 INCOME</h5>
<h6>  USDT {bonus2}  </h6><br></br> </td>

<td style={{width:"5%" ,}}></td>
</tr>

<tr><td></td><td></td><td></td></tr> 
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>MY BOOSTER - 200 INCOME</h5>
<h6>  USDT {bonus3}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr>
<tr><td></td><td></td><td></td></tr>
 
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>MY BOOSTER - 300 INCOME</h5>
<h6>  USDT {nbonus1}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr>

<tr><td></td><td></td><td></td></tr> 
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>MY BOOSTER - 500 INCOME</h5>
<h6>  USDT {nbonus2}  </h6><br></br> </td>

<td style={{width:"5%" ,}}></td>
</tr>

<tr><td></td><td></td><td></td></tr> 
<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h5>MY BOOSTER - 1000 INCOME</h5>
<h6>  USDT {nbonus3}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr>
<tr><td></td><td></td><td></td></tr>


<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
 
  <h5>TOTAL INCOME</h5>
<h6>   {tt}  </h6><br></br> </td>
<td style={{width:"5%" ,}}></td>

</tr>
<tr><td></td><td></td><td></td></tr>

<tr>  
    
<td style={{width:"5%" ,}}></td>
  <td style={{ backgroundColor: "#07e5c2", verticalAlign:"center", width :"90%", textAlign: "center",
color: "white",
}}>
  
  <h6> <input type ="text" style={{backgroundColor: "#07e5c2" ,textAlign:"center", border :"0" } }id ="test"  disabled></input></h6>
                            <Buy state={state} /></td>
                            <td style={{width:"5%" ,}}></td>

</tr>
<tr><td></td><td></td><td></td></tr>

</table>

      <br></br>  

       
   

   

                          
                          
    
    <div class="clearfix"></div>
  

<div class="copyrights">
	 <p>© 2023 SENJEPOWERS.LIVE . All Rights Reserved  </p>
</div>	

</div>
</div>


       

           
                   
	




     

  );
}

export default App;
